/* eslint-disable camelcase */
const permission = ['Add', 'Edit', 'Delete', 'View', 'View List of'];
const initialState = {
  userRolePermission: [
    { type: 'Users', permission },
    { type: 'Role', permission },
    { type: 'RolePermissions', permission: ['View List of'] }
  ],
  values: {}
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_WITH_EMAIL':
    case 'LOGIN_WITH_FB':
    case 'LOGIN_WITH_TWITTER':
    case 'LOGIN_WITH_GOOGLE':
    case 'LOGIN_WITH_GITHUB':
    case 'LOGIN_WITH_JWT':
    case 'LOGIN_USER':
    case 'FETCH_USER':
    case 'LOGOUT_WITH_JWT':
    case 'LOGOUT_WITH_FIREBASE': {
      const { user, user_role, token } = { ...action.payload };
      return { ...state, values: { user, token }, ...(user_role && { userRolePermission: user_role }) };
    }
    case 'CHANGE_ROLE':
      return { ...state, userRolePermission: action.userRole };
    default:
      return state;
  }
};
