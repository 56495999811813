import React from 'react';
import * as Icon from 'react-feather';

const navigationConfig = [
  {
    id: 'dashboard',
    title: 'DashBoard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    // permissions: ['admin', 'editor'],
    navLink: '/'
  },
  {
    id: 'security',
    title: 'Security',
    type: 'collapse',
    icon: <Icon.Shield size={20} />,
    permissions: ['Users', 'Role', 'RolePermissions'],
    children: [
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        permissions: ['Users'],
        navLink: '/security/users'
      },
      {
        id: 'roles',
        title: 'Roles',
        type: 'item',
        permissions: ['Role'],
        navLink: '/security/roles'
      },
      {
        id: 'role-permission',
        title: 'Role Permission',
        type: 'item',
        permissions: ['RolePermissions'],
        navLink: '/security/role-permissions'
      }
    ]
  },
  {
    id: 'master-data',
    title: 'MasterData',
    type: 'collapse',
    icon: <Icon.Database size={20} />,
    permissions: ['Asset', 'Customer', 'DataFile', 'Depository', 'Distributor', 'Message', 'MustSell', 'Plans', 'Product', 'Promotion', 'Question', 'Questionnaires', 'Voucher'],
    children: [
      {
        id: 'assets',
        title: 'Assets',
        type: 'item',
        permissions: ['Asset'],
        navLink: '/master-data/assets'
      },
      {
        id: 'customers',
        title: 'Customers',
        type: 'item',
        permissions: ['Customer'],
        navLink: '/master-data/customers'
      },
      {
        id: 'data-files',
        title: 'Data Files',
        type: 'item',
        permissions: ['DataFile'],
        navLink: '/master-data/data-files'
      },
      {
        id: 'depositories',
        title: 'Depositories',
        type: 'item',
        permissions: ['Depository'],
        navLink: '/master-data/depositories'
      },
      {
        id: 'distributors',
        title: 'Distributors',
        type: 'item',
        permissions: ['Distributor'],
        navLink: '/master-data/distributors'
      },
      {
        id: 'messages',
        title: 'Messages',
        type: 'item',
        permissions: ['Message'],
        navLink: '/master-data/messages'
      },
      {
        id: 'must-sells',
        title: 'Must Sells',
        type: 'item',
        permissions: ['MustSell'],
        navLink: '/master-data/must-sells'
      },
      {
        id: 'plans',
        title: 'Plans',
        type: 'item',
        permissions: ['Plans'],
        navLink: '/master-data/plans'
      },
      {
        id: 'products',
        title: 'Products',
        type: 'item',
        permissions: ['Product'],
        navLink: '/master-data/products'
      },
      {
        id: 'promotions',
        title: 'Promotions',
        type: 'item',
        permissions: ['Promotion'],
        navLink: '/master-data/promotions'
      },
      {
        id: 'questions',
        title: 'Questions',
        type: 'item',
        permissions: ['Question'],
        navLink: '/master-data/questions'
      },
      {
        id: 'questionnaires',
        title: 'Questionnaires',
        type: 'item',
        permissions: ['Questionnaires'],
        navLink: '/master-data/questionnaires'
      },
      {
        id: 'vouchers',
        title: 'Vouchers',
        type: 'item',
        permissions: ['Voucher'],
        navLink: '/master-data/vouchers'
      }
    ]
  },
  {
    id: 'transaction',
    title: 'Transaction',
    type: 'collapse',
    icon: <Icon.Layers size={20} />,
    permissions: [
      'AssetCheck',
      'AssetTransfer',
      'Billing',
      'Collection',
      'CreditNote',
      'Expense',
      'Invoice',
      'PurchaseInvoice',
      'PurchaseOrder',
      'PurchaseRequest',
      'ReturnInvoice',
      'SalesOrder',
      'StockTake',
      'Survey',
      'Visits'
    ],
    children: [
      {
        id: 'asset-check',
        title: 'Asset Check',
        type: 'item',
        permissions: ['AssetCheck'],
        navLink: '/transactions/asset-checks'
      },
      {
        id: 'asset-transfer',
        title: 'Asset Transfer',
        type: 'item',
        permissions: ['AssetTransfer'],
        navLink: '/transactions/asset-transfer'
      },
      {
        id: 'billings',
        title: 'Billings',
        type: 'item',
        permissions: ['Billing'],
        navLink: '/transactions/billing'
      },
      {
        id: 'collections',
        title: 'Collections',
        type: 'item',
        permissions: ['Collection'],
        navLink: '/transactions/collections'
      },
      {
        id: 'credit-notes',
        title: 'Credit Note',
        type: 'item',
        permissions: ['CreditNote'],
        navLink: '/transactions/credit-notes'
      },
      {
        id: 'expense',
        title: 'Expense',
        type: 'item',
        permissions: ['Expense'],
        navLink: '/transactions/expense'
      },
      {
        id: 'invoice',
        title: 'Invoice',
        type: 'item',
        permissions: ['Invoice'],
        navLink: '/transactions/invoice'
      },
      {
        id: 'purchase-invoice',
        title: 'Purchase Invoice',
        type: 'item',
        permissions: ['PurchaseInvoice'],
        navLink: '/transactions/purchase-invoice'
      },
      {
        id: 'purchase-order',
        title: 'Purchase Order',
        type: 'item',
        permissions: ['PurchaseOrder'],
        navLink: '/transactions/purchase-order'
      },
      {
        id: 'purchase-request',
        title: 'Purchase Request',
        type: 'item',
        permissions: ['PurchaseRequest'],
        navLink: '/transactions/purchase-request'
      },
      {
        id: 'return-invoice',
        title: 'Return Invoice',
        type: 'item',
        permissions: ['ReturnInvoice'],
        navLink: '/transactions/return-invoice'
      },
      {
        id: 'sales-orders',
        title: 'Sales Orders',
        type: 'item',
        permissions: ['SalesOrder'],
        navLink: '/transactions/sales-orders'
      },
      {
        id: 'stock-takes',
        title: 'Stock Takes',
        type: 'item',
        permissions: ['StockTake'],
        navLink: '/transactions/stock-takes'
      },
      {
        id: 'surveys',
        title: 'Surveys',
        type: 'item',
        permissions: ['Survey'],
        navLink: '/transactions/surveys'
      },
      {
        id: 'visits',
        title: 'Visits',
        type: 'item',
        permissions: ['Visits'],
        navLink: '/transactions/visits'
      }
    ]
  },
  {
    id: 'inventory',
    title: 'Inventory',
    type: 'collapse',
    icon: <Icon.Archive size={20} />,
    permissions: ['StockAdjustment', 'StockIn', 'StockOut', 'StockTransfer'],
    children: [
      {
        id: 'stock-adjustment',
        title: 'Stock Adjustment',
        type: 'item',
        permissions: ['StockAdjustment'],
        navLink: '/inventory/stock-adjustment'
      },
      {
        id: 'stock-in',
        title: 'Stock In',
        type: 'item',
        permissions: ['StockIn'],
        navLink: '/inventory/stock-in'
      },
      {
        id: 'stock-out',
        title: 'Stock Out',
        type: 'item',
        permissions: ['StockOut'],
        navLink: '/inventory/stock-out'
      },
      {
        id: 'stock-transfer',
        title: 'Stock Transfer',
        type: 'item',
        permissions: ['StockTransfer'],
        navLink: '/inventory/stock-transfer'
      }
    ]
  },
  {
    id: 'references',
    title: 'References',
    type: 'collapse',
    icon: <Icon.Link size={20} />,
    permissions: [
      'AccountType',
      'Area',
      'AssetBrand',
      'AssetCondition',
      'AssetModel',
      'AssetSupplier',
      'AssetType',
      'Banks',
      'City',
      'Country',
      'CustomerChannel',
      'CustomerClassification',
      'DeliveryType',
      'DimensionUnit',
      'District',
      'ExpenseCategory',
      'KeyAccount',
      'PriceGroup',
      'PackSize',
      'PackType',
      'ProductCategory',
      'Provinces',
      'Reason',
      'Region',
      'UserGroup',
      'WeightUnit'
    ],
    children: [
      {
        id: 'account-types',
        title: 'Account Types',
        type: 'item',
        permissions: ['AccountType'],
        navLink: '/references/account-types'
      },
      {
        id: 'areas',
        title: 'Areas',
        type: 'item',
        permissions: ['Area'],
        navLink: '/references/areas'
      },
      {
        id: 'asset-brands',
        title: 'Asset Brands',
        type: 'item',
        permissions: ['AssetBrand'],
        navLink: '/references/asset-brands'
      },
      {
        id: 'asset-conditions',
        title: 'Asset Conditions',
        type: 'item',
        permissions: ['AssetCondition'],
        navLink: '/references/asset-conditions'
      },
      {
        id: 'asset-models',
        title: 'Asset Models',
        type: 'item',
        permissions: ['AssetModel'],
        navLink: '/references/asset-models'
      },
      {
        id: 'asset-suppliers',
        title: 'Asset Suppliers',
        type: 'item',
        permissions: ['AssetSupplier'],
        navLink: '/references/asset-suppliers'
      },
      {
        id: 'asset-types',
        title: 'Asset Types',
        type: 'item',
        permissions: ['AssetType'],
        navLink: '/references/asset-types'
      },
      {
        id: 'banks',
        title: 'Banks',
        type: 'item',
        permissions: ['Banks'],
        navLink: '/references/banks'
      },
      {
        id: 'cities',
        title: 'Cities',
        type: 'item',
        permissions: ['City'],
        navLink: '/references/cities'
      },
      {
        id: 'countries',
        title: 'Countries',
        type: 'item',
        permissions: ['Country'],
        navLink: '/references/countries'
      },
      {
        id: 'customer-channels',
        title: 'Customer Channels',
        type: 'item',
        permissions: ['CustomerChannel'],
        navLink: '/references/customer-channels'
      },
      {
        id: 'customer-classifications',
        title: 'Customer Classifications',
        type: 'item',
        permissions: ['CustomerClassification'],
        navLink: '/references/customer-classifications'
      },
      {
        id: 'delivery-types',
        title: 'Delivery Types',
        type: 'item',
        permissions: ['DeliveryType'],
        navLink: '/references/delivery-types'
      },
      {
        id: 'dimension-units',
        title: 'Dimension Units',
        type: 'item',
        permissions: ['DimensionUnit'],
        navLink: '/references/dimension-units'
      },
      {
        id: 'districts',
        title: 'Districts',
        type: 'item',
        permissions: ['District'],
        navLink: '/references/districts'
      },
      {
        id: 'expense-categories',
        title: 'Expense Categories',
        type: 'item',
        permissions: ['ExpenseCategory'],
        navLink: '/references/expense-categories'
      },
      {
        id: 'key-accounts',
        title: 'Key Accounts',
        type: 'item',
        permissions: ['KeyAccount'],
        navLink: '/references/key-accounts'
      },
      {
        id: 'price-groups',
        title: 'Price Groups',
        type: 'item',
        permissions: ['PriceGroup'],
        navLink: '/references/price-groups'
      },
      {
        id: 'pack-sizes',
        title: 'Pack Sizes',
        type: 'item',
        permissions: ['PackSize'],
        navLink: '/references/pack-sizes'
      },
      {
        id: 'pack-types',
        title: 'Pack Types',
        type: 'item',
        permissions: ['PackType'],
        navLink: '/references/pack-types'
      },
      {
        id: 'product-categories',
        title: 'Product Categories',
        type: 'item',
        permissions: ['ProductCategory'],
        navLink: '/references/product-categories'
      },
      {
        id: 'provinces',
        title: 'Provinces',
        type: 'item',
        permissions: ['Provinces'],
        navLink: '/references/provinces'
      },
      {
        id: 'reasons',
        title: 'Reasons',
        type: 'item',
        permissions: ['Reason'],
        navLink: '/references/reasons'
      },
      {
        id: 'regions',
        title: 'Regions',
        type: 'item',
        permissions: ['Region'],
        navLink: '/references/regions'
      },
      {
        id: 'sub-districts',
        title: 'Sub Districts',
        type: 'item',
        permissions: ['SubDistrict'],
        navLink: '/references/sub-districts'
      },
      {
        id: 'uoms',
        title: 'UOMS',
        type: 'item',
        permissions: ['UOM'],
        navLink: '/references/uoms'
      },
      {
        id: 'user-groups',
        title: 'User Groups',
        type: 'item',
        permissions: ['UserGroup'],
        navLink: '/references/user-groups'
      },
      {
        id: 'weight-units',
        title: 'Weight Units',
        type: 'item',
        permissions: ['WeightUnit'],
        navLink: '/references/weight-units'
      }
    ]
  },
  {
    id: 'reports-main',
    title: 'Reports',
    type: 'collapse',
    icon: <Icon.BarChart2 size={20} />,
    permissions: [
      'Report',
    ],
    children: [
      {
        id: 'report-inventory-summary',
        title: 'Inventory Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/inventory-summary'
      },
      {
        id: 'report-inventory-movement',
        title: 'Inventory Movement',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/inventory-movement'
      },
      {
        id: 'report-inventory-stockin',
        title: 'Inventory Stock In',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/inventory-stockin'
      },
      {
        id: 'report-inventory-stockout',
        title: 'Inventory Stock Out',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/inventory-stockout'
      },
      {
        id: 'report-inventory-stock-adjustment',
        title: 'Inventory Stock Adjustment',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/inventory-stock-adjustment'
      },
      {
        id: 'report-asset-listing',
        title: 'Asset Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/asset-listing'
      },
      {
        id: 'report-asset-tracking',
        title: 'Asset Tracking',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/asset-tracking'
      },
      {
        id: 'sale-performance-doc-summary',
        title: 'Document Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-doc-summary'
      },
      {
        id: 'sale-performance-collection-summary-salesman',
        title: 'Collection Summary Salesman',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-collection-summary-salesman'
      },
      {
        id: 'sale-performance-collection-summary-customer',
        title: 'Collection Summary Customer',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-collection-summary-customer'
      },
      {
        id: 'sale-performance-document-listing',
        title: 'Document Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-document-listing'
      },
      {
        id: 'sale-performance-ar-report-summary',
        title: 'Ar Report Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-ar-report-summary'
      },
      {
        id: 'sale-performance-ar-report-detail',
        title: 'Ar Report Detail',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-ar-report-detail'
      },
      {
        id: 'sale-performance-saleorder-summary',
        title: 'Sale Order Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-saleorder-summary'
      },
      {
        id: 'sale-performance-saleorder-listing',
        title: 'Sale Order Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-saleorder-listing'
      },
      {
        id: 'sale-performance-route-vs-visit',
        title: 'Route VS Visit',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-route-vs-visit'
      },
      {
        id: 'sale-performance-outlet-performance',
        title: 'Outlet Performance',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/sale-performance-outlet-performance'
      },
      {
        id: 'report-promotion-trade-discount-summary',
        title: 'Promotion Trade Discount Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/promotion-trade-discount-summary'
      },
      {
        id: 'report-promotion-trade-offer-summary',
        title: 'Promotion Trade Offer Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/promotion-trade-offer-summary'
      },
      {
        id: 'report-promotion-master-summary',
        title: 'Promotion Master Summary',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/promotion-master-summary'
      },
      {
        id: 'report-others-customer-master-listing',
        title: 'Customer Master Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/others-customer-master-listing'
      },
      {
        id: 'report-others-product-master-listing',
        title: 'Product Master Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/others-product-master-listing'
      },
      {
        id: 'report-others-route-plan-master-listing',
        title: 'Route Plan Master Listing',
        type: 'item',
        permissions: ['Report'],
        navLink: '/reports/others-route-plan-master-listing'
      },
      // {
      //   id: 'report-inventory',
      //   title: 'Inventory',
      //   type: 'collapse',
      //   permissions: [
      //     'Report',
      //   ],
      //   children: [
          
      //   ]
      // },
      // {
      //   id: 'report-assets',
      //   title: 'Assets',
      //   type: 'collapse',
      //   permissions: [
      //     'Report',
      //   ],
      //   children: [
          
      //   ]
      // },
      // {
      //   id: 'report-sale-performance',
      //   title: 'Sale Performance',
      //   type: 'collapse',
      //   permissions: [
      //     'Report',
      //   ],
      //   children: [
          
      //   ]
      // },
      // {
      //   id: 'report-promotion',
      //   title: 'Promotion',
      //   type: 'collapse',
      //   permissions: [
      //     'Report',
      //   ],
      //   children: [
          
      //   ]
      // },
      // {
      //   id: 'report-others',
      //   title: 'Others',
      //   type: 'collapse',
      //   permissions: [
      //     'Report',
      //   ],
      //   children: [
          
      //   ]
      // }
    ]
  },
];

export default navigationConfig;
