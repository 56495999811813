const initialState = {
  isSuccess: false,
  message: ''
};

const success = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUCCESS':
      return { ...state, ...action };
    default:
      return state;
  }
};

export default success;
