const initialState = {
  addProducts: [],
  condition: [],
  pictures: []
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_PRODUCTS':
      return { ...state, addProducts: [...action.data] };
    case 'SET_DATA':
      return { ...state, ...action.data };
    case 'SET_PRODUCTS':
      if (action.store === 'condition') {
        let conditions = { ...(state.condition[action.index] || {}) };
        conditions = {
          addProducts: [
            ...(conditions.addProducts || []),
            {
              ...action.data,
              newId:
                ((conditions.addProducts || []).length > 0 && conditions.addProducts[(conditions.addProducts || []).length - 1].newId + 1) ||
                (conditions.addProducts || []).length + 1
            }
          ]
        };
        state.condition[action.index] = conditions;
        return { ...state };
      }
      return {
        ...state,
        addProducts: [
          ...state.addProducts,
          { ...action.data, newId: (state.addProducts.length > 0 && state.addProducts[state.addProducts.length - 1].newId + 1) || state.addProducts.length + 1 }
        ]
      };
    case 'REMOVE_PRODUCTS':
      if (action.store === 'condition') {
        state.condition[action.index].addProducts =
          (state.condition[action.index].addProducts && state.condition[action.index].addProducts.filter(f => f.newId !== action.id)) || [];
        return { ...state };
      }
      return { ...state, addProducts: (state.addProducts && state.addProducts.filter(f => f.newId !== action.id)) || [] };
    case 'UNSET_PRODUCTS':
      return { ...state, [action.store]: [] };
    default:
      return state;
  }
};

export default data;
