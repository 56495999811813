/* eslint-disable */
import { history } from '../../../history';
import AuthService from '../../../services/AuthService';

export const changeRole = role => dispatch => dispatch({ type: 'CHANGE_ROLE', userRole: role });

export const loginUser = user => async dispatch => {
  if (await AuthService.login(user)) {
    const res = await AuthService.user();
    if (res) {
      dispatch({
        type: 'FETCH_USER',
        payload: { ...res }
      });
      history.push('/');
    }
  }
};

export const fetchUser = user => async dispatch => {
  const res = await AuthService.user();
  if (res) {
    dispatch({
      type: 'FETCH_USER',
      payload: { ...res }
    });
  }
};

export const logoutUser = () => dispatch => {
  const logged_out = AuthService.logout();
  if (logged_out) history.push('/pages/login');
};
