import { history } from '../history';
import { client } from './Api';
import DispatchService from './DispatchService';
import Cookies from 'js-cookie'

class AuthService extends DispatchService {
  constructor() {
    super();
    this.api = `/api/v1`;
  }

  login = async user => {
    Cookies.remove('sme_auth')
    try {
      const res = await client.post(`/users/login`, user);
      if (res.data) {
        const { data } = res.data;
        Cookies.set('sme_auth', data.access_token)
        return true;
      }
      return false;
    } catch (e) {
      this.setError(e);
      return false;
    }
  };

  logout = () => {
    Cookies.remove('sme_auth')
    return true;
  };

  user = async () => {
    try {
      const token = Cookies.get('sme_auth') 
      if (!token) {
        if (window.location.pathname !== '/privacy_policy') {
          history.push('/pages/login');
        }
        return false;
      }
      const isExpire = await this.isTokenExpire(token)
      if (!isExpire) {
        const res = await client.get(`/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          }
        });
        if (res.status === 200) {
          const user = { ...res.data.data, token};
          return user
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  isTokenExpire = async token => {
    try {
      const res = await client.get(`/checkExpire`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      });
      if (res.status === 200) {
        return false;
      }
      return true;
    } catch (e) {
      Cookies.remove('sme_auth')
      return true;
    }
  };
}

export default new AuthService();
