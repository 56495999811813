import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Layout } from './utility/context/Layout';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './redux/storeConfig/store';
import Spinner from './components/@vuexy/spinner/Fallback-spinner';
import { IntlProviderWrapper } from './utility/context/Internationalization';
import { AdvanceSearch } from './utility/context/AdvanceSearch';
import { PermissionCheck } from './utility/context/PermissionCheck';
import './index.scss';
import './@fake-db';

const LazyApp = lazy(() => import('./App'));

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Layout>
            <IntlProviderWrapper>
              <AdvanceSearch>
                <PermissionCheck>
                  <LazyApp />
                </PermissionCheck>
              </AdvanceSearch>
            </IntlProviderWrapper>
          </Layout>
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
