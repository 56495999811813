const initialState = {
  isError: false,
  message: '' || {}
};

const errors = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, ...action };
    default:
      return state;
  }
};

export default errors;
