import { combineReducers } from 'redux';
import customizer from './customizer';
import auth from './auth';
import navbar from './navbar/Index';
import errors from './errors/errorsReducer';
import refresh from './refresh/refreshReducer';
import success from './success/successReducer';
import data from './data/dataReducer';

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  refresh,
  success,
  errors,
  data
});

export default rootReducer;
