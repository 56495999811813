import React from 'react';

const ContextPermissionCheck = React.createContext();

class PermissionCheck extends React.Component {
    state = {
        loadPermission: []
    };

    setPermissionCheck = (data) => {
        this.setState({ loadPermission: data });
    };

    render() {
        const { children } = this.props;
        return (
        <ContextPermissionCheck.Provider value={{
            ...this.state, 
            setPermissionCheck: this.setPermissionCheck
        }}>
            {children}
        </ContextPermissionCheck.Provider>
        );
    }
}

export { PermissionCheck, ContextPermissionCheck };
