import { history } from '../../../history';
import AuthService from '../../../services/AuthService';

export const errors = (isError, message) => dispatch => {
    dispatch({ type: 'SET_ERROR', isError, message: message || null });
    if (message === 'Login session is expired') {
        const logged_out = AuthService.logout();
        if (logged_out) history.push('/pages/login');
    }
}
