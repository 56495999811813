import React from 'react';

const ContextAdvanceSearch = React.createContext();

class AdvanceSearch extends React.Component {
    state = {
        queryString: '',
        enableAdvanceSearch: false,
        loadListOption: false
    };

    saveQueryString = (value) => {
        this.setState({ queryString: value });
    };

    setEnableAdvanceSearch = (value) => {
        this.setState({ enableAdvanceSearch: value });
    };

    setLoadListOption = (value) => {
        this.setState({ loadListOption: value });
    };

    render() {
        const { children } = this.props;
        return (
        <ContextAdvanceSearch.Provider value={{
            ...this.state, 
            saveQueryString: this.saveQueryString, 
            setEnableAdvanceSearch: this.setEnableAdvanceSearch,
            setLoadListOption: this.setLoadListOption
        }}>
            {children}
        </ContextAdvanceSearch.Provider>
        );
    }
}

export { AdvanceSearch, ContextAdvanceSearch };
