import React from 'react';
import { NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import * as Icon from 'react-feather';
import classnames from 'classnames';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Autocomplete from '../../../components/@vuexy/autoComplete/AutoCompleteComponent';
import { IntlContext } from '../../../utility/context/Internationalization';
import { logoutUser } from '../../../redux/actions/auth/loginActions';

const logout = async props => {
  props.logoutUser();
};
const UserDropdown = props => {
  return (
    <DropdownMenu right>
      {/* <DropdownItem divider /> */}
      <DropdownItem tag='a' onClick={() => logout(props)}>
        <Icon.Power size={14} className='mr-50' />
        <span className='align-middle'>
          <FormattedMessage id='Log Out' />
        </span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    langDropdown: false
  };

  componentDidMount() {
    // axios.get("/api/main-search/data").then(({ data }) => {
    //   this.setState({ suggestions: data.searchResult })
    // })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    });
  };

  handleLangDropdown = () => this.setState({ langDropdown: !this.state.langDropdown });

  render() {
    const { user } = this.props;
    return (
      <ul className='nav navbar-nav navbar-nav-user float-right'>
        <IntlContext.Consumer>
          {context => {
            const langArr = {
              en: 'English',
              th: 'ไทย'
            };
            return (
              <Dropdown tag='li' className='dropdown-language nav-item' isOpen={this.state.langDropdown} toggle={this.handleLangDropdown} data-tour='language'>
                <DropdownToggle tag='a' className='nav-link'>
                  <ReactCountryFlag className='country-flag' countryCode={context.state.locale === 'en' ? 'us' : context.state.locale} svg />
                  <span className='d-sm-inline-block d-none text-capitalize align-middle ml-50'>{langArr[context.state.locale]}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag='a' onClick={e => context.switchLanguage('en')}>
                    <div className="action-items">
                      <span className="left">
                        <ReactCountryFlag className='country-flag' countryCode='us' svg />
                      </span>
                      <span className="right">
                      <span className='ml-1'>English</span>
                      </span>
                    </div>
                  </DropdownItem>
                  <DropdownItem tag='a' onClick={e => context.switchLanguage('th')}>
                    <div className="action-items">
                      <span className="left">
                        <ReactCountryFlag className='country-flag' countryCode='th' svg />
                      </span>
                      <span className="right">
                      <span className='ml-1'>ไทย</span>
                      </span>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            );
          }}
        </IntlContext.Consumer>

        <NavItem className='nav-search' onClick={this.handleNavbarSearch}>
          <NavLink className='nav-link-search'>
            <Icon.Search size={21} data-tour='search' />
          </NavLink>
          <div
            className={classnames('search-input', {
              open: this.state.navbarSearch,
              'd-none': this.state.navbarSearch === false
            })}>
            <div className='search-input-icon'>
              <Icon.Search size={17} className='primary' />
            </div>
            <Autocomplete
              className='form-control'
              suggestions={this.state.suggestions}
              filterKey='title'
              filterHeaderKey='groupTitle'
              grouped
              placeholder='Search...'
              autoFocus
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch: false });
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  });
                  this.props.handleAppOverlay('');
                }
              }}
              customRender={(item, i, filteredData, activeSuggestion, onSuggestionItemClick, onSuggestionItemHover) => {
                const IconTag = Icon[item.icon ? item.icon : 'X'];
                return (
                  <li
                    className={classnames('suggestion-item', {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() => onSuggestionItemHover(filteredData.indexOf(item))}>
                    <div
                      className={classnames({
                        'd-flex justify-content-between align-items-center': item.file || item.img
                      })}>
                      <div className='item-container d-flex'>
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img src={item.file} height='36' width='28' alt={item.title} />
                        ) : item.img ? (
                          <img className='rounded-circle mt-25' src={item.img} height='28' width='28' alt={item.title} />
                        ) : null}
                        <div className='item-info ml-1'>
                          <p className='align-middle mb-0'>{item.title}</p>
                          {item.by || item.email ? <small className='text-muted'>{item.by ? item.by : item.email ? item.email : null}</small> : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className='meta-container'>
                          <small className='text-muted'>{item.size ? item.size : item.date ? item.date : null}</small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className='search-input-close'>
              <Icon.X
                size={24}
                onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false
                  });
                  this.props.handleAppOverlay('');
                }}
              />
            </div>
          </div>
        </NavItem>
        <UncontrolledDropdown tag='li' className='dropdown-user nav-item' style={{ padding: '1.75rem 0.5rem' }}>
          <DropdownToggle tag='a' className='nav-link dropdown-user-link' style={{ padding: '0px' }}>
            <div className='user-nav d-sm-flex d-none'>
              <span className='user-name text-bold-600 mb-0'>
                {user?.first_name} {user?.last_name}
              </span>
              {user?.status && <span className='user-status'>{user?.status}</span>}
            </div>
            <span data-tour='user'>
              <Icon.User alt='avatar' style={{ marginTop: '-9px' }} />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default connect(
  state => ({
    user: state.auth?.login?.values?.user
  }),
  { logoutUser }
)(NavbarUser);
