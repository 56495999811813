import { store } from '../redux/storeConfig/store';
import { errors, success, refreshList, refreshAll } from '../redux/actions';

export default class DispatchService {
  setError = message => {
    if (message?.response?.data) {
      if (message?.response?.data === 'jwt malformed' || message?.response?.data === 'jwt expired') {
        message = 'Login session is expired'
      }
    }
    store.dispatch(errors(true, message.response || message));
  };

  setSuccess = message => {
    store.dispatch(success(true, message));
  };

  refreshList = () => {
    store.dispatch(refreshList(true));
  };

  refreshAll = () => {
    store.dispatch(refreshAll(true));
  };
}
