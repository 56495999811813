import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';
const Api = `/api/v1`;
const client = axios.create({
  baseURL: Api
});
client.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 404) err.response.data = `${err.config.url} ${err.response.data.data || 'not found.'}`;
    else err.response.data = typeof err.response.data === 'object' ? err.response.data.data : err.response.data;

    if (!err.response.data) {
      const error = JSON.parse(err.response.request.response).original;
      err.response.data = (error && error.message) || err.response.statusText;
    }
    // throw new Error(`${err.config.url} not found`);
    throw err;
  }
);
export { client };
